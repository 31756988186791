
(function (win) {
    var docEl = win.document.documentElement,
        tid;

    win.refreshRem = function (wid) {
        var width = docEl.getBoundingClientRect().width;

        //  默认 1920 为原稿，除以100可得各元素的rem
        rem = width / 1920 * 100;
       
        // 小于1200 用 1200 为原稿
        if (width <= 1200) {
            rem = 1200 / 1920 * 100;
        }

        docEl.style.fontSize = rem + "px";
        var actualSize = parseFloat(window.getComputedStyle(docEl)["font-size"]);
        if (actualSize !== rem) {
            var remScaled = rem / (actualSize / rem);
            docEl.style.fontSize = remScaled + "px"
        }
    }


    function dbcRefresh() {
        clearTimeout(tid);
        tid = setTimeout(refreshRem, 100)
    }

    win.addEventListener("resize", function () { dbcRefresh() }, false);
    win.addEventListener("pageshow", function (e) {
        if (e.persisted) { dbcRefresh() }
    }, false);
    win.refreshRem();
})(window);
<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
	export default{
		data(){
			return{}
		},
		created() {			
			let userinfo = localStorage.getItem('userinfo')
			// if(!userinfo){
			// 	this.$router.push('/login').catch(err => { console.log(err) })			
			// 	return
			// }			
			this.$store.commit('setUserInfo', JSON.parse(userinfo))
			// this.$get({
			// 	url:'admin/index/info'
			// }).then((res)=>{
			// 	localStorage.setItem('userinfo', JSON.stringify(res));//本地缓存
			// 	this.$store.commit('setUserInfo', res)
			// })
		}
	}
</script>
<style lang="scss">
	@import '../node_modules/@wangeditor/editor/dist/css/style.css';
*{
	padding: 0;
	margin: 0;
	
	box-sizing: border-box;
}
	::v-deep .el-time-panel__footer{
		display: flex;
		justify-content: flex-end;
	}
#app,body{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  
}
::v-deep .dasuju{
	background-color: rgb(24,26,33);
	.el-scrollbar{
		background-color: rgb(24,26,33);
	}
}
.one_line_over{
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;				//溢出内容隐藏
	text-overflow: ellipsis;		//文本溢出部分用省略号表示
	display: -webkit-box;			//特别显示模式
	-webkit-line-clamp: 1;			//行数
	line-clamp: 1;					
	-webkit-box-orient: vertical;	//盒子中内容竖直排列
}
.tow_line_over{
	text-overflow: -o-ellipsis-lastline !important;
	overflow: hidden !important;				//溢出内容隐藏
	text-overflow: ellipsis !important;		//文本溢出部分用省略号表示
	display: -webkit-box !important;			//特别显示模式
	-webkit-line-clamp: 2 !important;			//行数
	line-clamp: 2 !important;					
	-webkit-box-orient: vertical !important;	//盒子中内容竖直排列
}
.box{
	width: 100%;
	height: calc(100vh - 70px) !important;
	overflow-y: auto;
	padding: 8px;
	box-sizing: border-box;
}
.box_mm{
	height: calc(100vh - 110px) !important;
}
.main_box{
	height: calc(100vh - 70px);
	
	width: calc(100vw - 200px) !important;
	background-color: rgb(240,242,245);
}
.main_box_mini{
	width: calc(100vw - 70px) !important;
}
.box_table{
	border-radius: 10px;
	background-color: #fff;
	box-sizing: border-box;
	padding: 10px;
	padding-bottom: 0;
}
::v-deep .del_btn{
	color: red !important;
}
.del_btn{
	color: red !important;
}
.mybtn{
	padding: 5px 0.2rem;
	border: 1px solid rgb(31,101,218);
	color: rgb(31,101,218);
	font-size: 0.16rem;
	border-radius: 5px;
	cursor: pointer;
}
.mybtnfill{
	padding: 6px 0.2rem;
	background-color: rgb(31,101,218);
	color: #fff;
	font-size: 0.16rem;
	border-radius: 5px;
	cursor: pointer;
}
::v-deep .el-table__fixed-right:before{
	background-color: #fff !important;
}

</style>

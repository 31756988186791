import store from '../../store/index'
import { Loading, Message } from "element-ui";
import router from "../../router/index";//路由


// 消息提示  ui组件： pc 饿了么ui的 Message    移动 vant的 Toast
let loadingS = "";
export function ToastMessage(options) {
    // 判断是否传值
    if (!options) {
        // 清除样式
        loadingS.close();
    } else {
       // pc端
           // 判断是否是加载
           if (options.isLoading) {
               // 加载提示
               loadingS = Loading.service({
                   lock: true,
                   text: options.message ? options.message : "加载中...",
                   spinner: 'el-icon-loading',
                   background: 'rgba(0, 0, 0, 0.7)'
               });
           } else {
               // 消息提示
               Message({
                   message: options.message ? options.message : "加载中...",//提示文案
                   type: options.type ? options.type : "warning",//提示类型
                   duration: options.duration ? options.duration : 2000,//展示时长
                   showClose: true,//是否显示关闭按钮
               });
           }
       
    }
}



/** 
 * 倒计时 方法
 * @param event  // 点击对象
 * @param initText  // 默认文案
 * @param timeNum  // 倒计时初始时间
 * @param backFunc  // 回调方法
 */
export function countDown(event, initText, timeNum, backFunc) {
    // 判断 当前对象的 值 是 初始文案，才可以进入
    // 阻止多次点击
    if (event.innerText == initText) {
        // 返回初始值
        backFunc && backFunc(timeNum + "s");
        // 循环赋值
        let downTimer = setInterval(() => {
            timeNum--;
            backFunc && backFunc(timeNum + "s");

            // 小于0，赋值初始文案
            if (timeNum <= 0) {
                clearInterval(downTimer);
                backFunc && backFunc(initText);
            }
        }, 1000)
    }
}


//时间戳转换方法    time:秒级时间戳数字 hours:是否返回小时
export function formatDate(time, hours) {
    var date = new Date(Number(time) * 1000);
    var YY = date.getFullYear() + '年';
    var MM = (date.getMonth() + 1) + '月';
    var DD = date.getDate() + '日';
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';//小时
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';//分
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());//秒
    if (hours) {
        return YY + MM + DD + hh + mm + ss
    } else {
        return YY + MM + DD
    }
}


// 资源地址 拼接
export function resourSplic(url) {
    return process.env.VUE_APP_RESOUR + url;
}
// 资源地址 拼接
export function resourSplic2(url) {
    return process.env.VUE_APP_RESOUR2 + url;
}
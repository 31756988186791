import Vue from 'vue'
import VueRouter from 'vue-router'
import routerdata from './data.js'
import { Loading, Message } from "element-ui";
import store from '../store/index.js'
import HomeView from '../views/guanwang/HomeView.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/login.vue')
	},
	{
	  path: '/',
	  name: 'index',
	  component: HomeView,
	  redirect:'/index',
	  children: [
	    {
	      path: 'index',
	      component: () => import('../views/guanwang/homeComponents/indexHome.vue')
	    },{
	      path: 'secondList',
	      name:'secondList',
	      component: () => import('../views/guanwang/secondPage/secondIndex.vue')
	    }
	  ]
	},
]


const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})


let data = {
		path: '/system',
		name: 'system',
		component: () => import('../views/system/index.vue'),
		redirect: '/system/home',
		children: []

	}
	routerdata.forEach((item, index) => {
		if(item.meta&&item.meta.isdan){
			let obj = {
				path: item.path,
				meta:item.meta?item.meta:{ischildren:false},
				component: () => import('../views/system' + item.url)
			}
			data.children.push(obj)
		}
		if (item.children) {
			item.children.forEach((item2, index2) => {
				let obj = {}
				if (item2.children) {
					item2.children.forEach((item3, index3) => {
						obj = {
							path: item3.path,
							meta:item3.meta?item3.meta:{ischildren:false},
							component: () => import('../views/system' + item3.url)
						}
						data.children.push(obj)
					})
				} else {
					obj = {
						path: item2.path,
						meta:item2.meta?item2.meta:{ischildren:false},
						component: () => import('../views/system' + item2.url)
					}
					data.children.push(obj)
				}
			})
		}
		

	})
	console.log(data)
	router.addRoute(data)

router.beforeEach((to, from, next) => {
	if(to.path == '/'||to.path=='/secondList'||to.path=='/index'||to.path=='/login'){		
		next()
		return
	}
	let userinfo = localStorage.getItem('userinfo')
	if(!userinfo&&to.fullPath != '/login'){
		next({path:'/login'})
		return
	}
	
	
	
	//权限校验
	// if(to.fullPath != '/login'){
	// 	let user_info =JSON.parse(localStorage.getItem('userinfo'))
	// 	let cango = false
	// 	user_info.menu.forEach((item,index)=>{
	// 		if(item.path==to.fullPath){
	// 			cango = true
	// 		}
	// 		if(item.children&&item.children.length>0){
	// 			item.children.forEach((item2)=>{
	// 				if(item2.path==to.fullPath){
	// 					cango = true
	// 				}
	// 			})
	// 		}
	// 	})
	// 	console.log(to)
	// 	if(!cango&&!to.meta.ischildren){//没有权限
	// 		// 消息提示
	// 		Message({
	// 		    message:"没有权限",//提示文案
	// 		    type: "warning",//提示类型
	// 		    duration: 2000,//展示时长
	// 		    showClose: true,//是否显示关闭按钮
	// 		});
	// 		next({path:'/'})
	// 	}
	// }
	
	
	
	
	
	
	

	//设置左侧菜单同步
	let obj = {
		path: '',
		name: '',
		father_name: ''
	}

	if (to.fullPath != '/system/home') {
		routerdata.forEach((item, index) => {
			if(item.meta&&item.meta.isdan&&item.path == to.path){
				obj.path = item.path
				obj.name = item.name
				obj.father_name = item.name	
				return false
			}
			if (item.children) {
				item.children.forEach((item2, index2) => {
					if (item2.children) {
						item2.children.forEach((item3, index3) => {
							if (item3.path == to.path) {
								obj.path = item3.path
								obj.name = item3.name
								obj.name = item3.name
								obj.father_name = item2.name
							}
						})
					} else {
						if (item2.path == to.path) {
							obj.path = item2.path
							obj.name = item2.name
							obj.father_name = item.name
						}
					}

				})
			}
		})
	} else {
		next()
		return false
	}
	store.commit('sethistor', obj)
	next()
})

export default router
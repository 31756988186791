<template>
  <div class="logo">
    <div class="flex" style="padding: 0 2.6rem;width: 100%;">
        <img src="@/assets/home/logo.png" alt="" class="logo_pic" style="cursor: pointer;" @click="gohome">
        <div style="cursor: pointer;" @click="gohome">
            <img src="@/assets/home/logo_text.png" class="logo_text" alt="">
            <img src="@/assets/home/logo_text_En.png" class="logo_text_En" alt="">
        </div>
    </div>    
   
  </div>
</template>

<script>
export default {
	data() {
		return{
			
		}
	},
	methods:{
		gohome(){
			this.$router.push('/')
		}
	}
}
</script>

<style scoped lang="scss">
.logo{
    margin-top: 1.54rem;
    margin-bottom: .76rem;
    position: relative;
}
.logo_pic{
    width: 1.21rem;
    height: auto;
    margin-right: .17rem;
}
.logo_text{
    width: 7.51rem;
    height: auto;
    margin-bottom: .15rem;
}
.logo_text_En{
    width: 7.53rem;
    height: auto;
}
.flex{
    display: flex;
    align-items: center;
    div{
        display: flex;
        flex-direction: column;
    }
}


</style>
<template>
	<!--详情弹窗-->
	<el-dialog
	  title="详情"
	  :visible.sync="show"
	  width="40%"
	  :before-close="close">
	  <el-form ref="form" label-width="150px">
	    <el-form-item v-for="(item,index) in showdata" :key="index" :label="item.name" :prop="item.prop">
	      <el-input v-model="item.data" disabled></el-input>
	    </el-form-item>
	  </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="close">取 消</el-button>
	    <el-button type="primary" @click="close">确 定</el-button>
	  </span>
	</el-dialog>
</template>

<script>
	export default{
		props:["show","showdata"],
		data() {
			return{
				
			}
		},
		methods:{
			close(){
				this.$emit('close_detail',false)
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep .el-dialog__header{
		display: flex;
		border-bottom: 1px solid #e0e0e0;
	}
	::v-deep .el-dialog__footer span{
		display: flex;
		justify-content: flex-end;
	}
	::v-deep .el-dialog__footer{
		border-top: 1px solid #e0e0e0;
		padding: 10px 20px 12px;
	}
	::v-deep .el-dialog__footer .el-button{
		padding:8px 12px;
	}
	::v-deep .el-input.is-disabled .el-input__inner{
		color: #555;
	}
</style>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		history: '',
		closeleftmenu: false,
		userinfo: {
			token: ''
		}
	},
	mutations: {
		setUserInfo(state, msg) {
			state.userinfo = msg
		},
		clearuserinfo(state) {
			state.userinfo = {
				token: ''
			}
		},
		setmenuclose(state) {
			state.closeleftmenu = !state.closeleftmenu
		},
		sethistor(state, info) {
			state.history = info
		},
	},
	actions: {},
	modules: {}
})
import { render, staticRenderFns } from "./copyRight.vue?vue&type=template&id=35e57a98&scoped=true&"
import script from "./copyRight.vue?vue&type=script&lang=js&"
export * from "./copyRight.vue?vue&type=script&lang=js&"
import style0 from "./copyRight.vue?vue&type=style&index=0&id=35e57a98&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35e57a98",
  null
  
)

export default component.exports